import axiosInstance from '../../translator';
import { reportAPI } from '../../constants/apis/apiEndpoints';

const generateQueryParams = (payload) => {
  const params = {};
  params.limit = payload?.limit || 5;
  params.offset = payload?.offset || 1;
  params.search = payload?.search || '';
  params.ordering = payload?.ordering || '-starts_at';
  return params;
};

export const getAssessmentReportData = async (payload) => {
  const params = generateQueryParams(payload);
  //Append filters to the query params if exists
  payload?.assessment_name
    ? (params.assessment_name = payload?.assessment_name)
    : '';
  payload?.dateBefore ? (params.date_before = payload?.dateBefore) : '';
  payload?.dateAfter ? (params.date_after = payload?.dateAfter) : '';
  payload?.proctor_name ? (params.proctor_name = payload?.proctor_name) : '';

  delete payload?.date;

  return axiosInstance
    .get(`${reportAPI?.assessmentReport}`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDetailReport = async (payload) => {
  return axiosInstance
    .get(
      `${reportAPI?.detailReportView}/${payload.candidateId}/${payload.examId}`,
      payload
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCandidateViolations = async (payload) => {
  const params = generateQueryParams(payload);
  return axiosInstance
    .get(
      `${reportAPI?.violationList}/${payload.candidateId}/${payload.examId}`,
      { params }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getViolationReport = async (payload) => {
  const params = generateQueryParams(payload);
  return axiosInstance
    .get(
      `${reportAPI?.violationList}/${payload.candidateId}/${payload.examId}`,
      { params }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getReviewSessionReport = async (payload) => {
  return axiosInstance
    .get(
      `${reportAPI?.reviewSessionDetail}/${payload.candidateId}/${payload.examId}`,
      payload
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAssessmentCandidates = async (payload) => {
  const params = generateQueryParams(payload);
  payload?.search ? (params.name = payload?.search) : '';

  return axiosInstance
    .get(`${reportAPI?.assessmentCandidates}/${payload.examId}`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAIAlerts = async (payload) => {
  return axiosInstance
    .get(`${reportAPI?.getAIAlert}/${payload.candidateId}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const setCandidateProctoringStatus = async (payload) => {
  return axiosInstance
    .patch(`${reportAPI?.candidateStatus}/${payload.candidateId}`, {
      proctoring_result: payload.isApproved ? 'approve' : 'reject',
      proctors_comments: payload?.proctors_comments,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getReportCandidates = async (payload) => {
  const params = generateQueryParams(payload);
  //Append filters to the query params if exists
  if (payload?.candidate_name) {
    let fullNameArr = payload?.candidate_name?.split(' ');
    if (fullNameArr.length === 2) {
      fullNameArr ? (params.first_name = fullNameArr[0]) : '';
      fullNameArr[1] ? (params.last_name = fullNameArr[1]) : '';
    } else {
      params.candidate_name = payload?.candidate_name;
    }
  }
  payload?.assessment_name
    ? (params.assessment_name = payload?.assessment_name)
    : '';
  payload?.dateBefore ? (params.date_before = payload?.dateBefore) : '';
  payload?.dateAfter ? (params.date_after = payload?.dateAfter) : '';

  delete payload?.date;

  return axiosInstance
    .get(reportAPI?.assessmentCandidates, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const generateDownloadQueryParams = (payload) => {
  const params = {};
  params.search = payload?.search || '';
  params.name = payload?.search || '';
  params.ordering = payload?.ordering || '-starts_at';
  return params;
};

export const downloadCandidatesReportApi = async (payload) => {
  const params = generateDownloadQueryParams(payload);
  payload?.isCSV ? (params.download_csv = true) : '';
  payload?.isXLS ? (params.download_xls = true) : '';
  return axiosInstance
    .get(`${reportAPI?.assessmentCandidates}/${payload?.examId}`, {
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getExamVideoLink = async (payload) => {
  return axiosInstance
    .get(
      `${reportAPI?.getExamVideoLink}/${payload?.examId}/${payload?.candidateId}/${payload?.userId}`,
      payload
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPortalVideoStatus = async (payload) => {
  return axiosInstance
    .get(
      `${reportAPI?.portalExamVideo}/${payload?.examId}/${payload?.candidateId}/${payload?.userId}?status=true`,
      payload
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postExamComment = async (payload) => {
  return axiosInstance
    .post(`${reportAPI?.postExamComment}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExamComments = async (examId, test_taker_id) => {
  return axiosInstance
    .get(
      `${reportAPI?.getExamComment}/${examId}/${test_taker_id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const downloadCandidatesInDateRangeReportApi = async (payload) => {
  const params = {};
  params.ordering = payload?.ordering || '-starts_at';
  payload?.assessment_name
    ? (params.assessment_name = payload?.assessment_name)
    : '';
  payload?.dateBefore ? (params.date_before = payload?.dateBefore) : '';
  payload?.dateAfter ? (params.date_after = payload?.dateAfter) : '';
  if (payload?.candidate_name) {
    let fullNameArr = payload?.candidate_name?.split(' ');
    if (fullNameArr.length === 2) {
      fullNameArr ? (params.first_name = fullNameArr[0]) : '';
      fullNameArr[1] ? (params.last_name = fullNameArr[1]) : '';
    } else {
      params.candidate_name = payload?.candidate_name;
    }
  }
  payload?.isCSV ? (params.download_csv = true) : '';
  return axiosInstance
    .get(`${reportAPI?.assessmentCandidates}`, {
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

import React, { Suspense, useEffect, useRef } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Box from 'iiht-proctoring-ui-components/build/components/Box';
import { Role } from './data/roles';
import ProtectedRoute from './config/ProtectedRoute';
import { ROUTES } from './constants/routesConstants';
import NotFoundPage from './components/common/NotFound';
import { localStorageHelper } from './utils/storageHelper';
import CircularProgress from 'iiht-proctoring-ui-components/build/components/Progress/CircularProgress';
import './App.scss';

const DashboardContainer = React.lazy(() =>
  import('./components/dashboard/DashboardContainer')
);

const ViewTestTakersDashboardContainer = React.lazy(() =>
  import('./components/dashboard/ViewTestTakersDashboardContainer')
);

const ViewAssessmentsDashboardContainer = React.lazy(() =>
  import('./components/dashboard/ViewAssessmentsDashboardContainer')
);

const TenantContainer = React.lazy(() =>
  import('./components/tenant/TenantContainer')
);

const TenantAddEdit = React.lazy(() =>
  import('./components/tenant/TenantAddEdit')
);

const Assess = React.lazy(() => import('./components/access'));

const ProctorAddEdit = React.lazy(() =>
  import('./components/proctor/ProctorAddEdit')
);

const ProctorContainer = React.lazy(() =>
  import('./components/proctor/ProctorContainer')
);

const ProctorView = React.lazy(() =>
  import('./components/proctor/ProctorView')
);

const ProctorEdit = React.lazy(() =>
  import('./components/proctor/ProctorEdit')
);

const TenantAdminAdd = React.lazy(() =>
  import('./components/tenantAdmin/TenantAdmin')
);
const TenantAdminContainer = React.lazy(() =>
  import('./components/tenantAdmin/TenantAdminContainer')
);
const TenantAdminView = React.lazy(() =>
  import('./components/tenantAdmin/TenantAdminView')
);
const TenantAdminEdit = React.lazy(() =>
  import('./components/tenantAdmin/TenantAdminEdit')
);

const ProfileSettingsContainer = React.lazy(() =>
  import('./components/profileSettings/ProfileSettingsContainer')
);

const AssessmentsViewList = React.lazy(() =>
  import('./components/assessment/Assessment')
);

const AssessmentView = React.lazy(() =>
  import('./components/assessment/AssessmentView')
);

const ProctoringContainer = React.lazy(() =>
  import('./components/proctoring/ProctoringContainer')
);

const SuperProctoringContainer = React.lazy(() =>
  import('./components/superProctoring/SuperProctoringContainer')
);

const SquadProctoringContainer = React.lazy(() =>
  import('./components/squadProctoring/SquadProctoringContainer')
);

const ApproverVerifyContainer = React.lazy(() =>
  import('./components/approverVerify/ApproverVerifyContainer')
);

const VerificationDone = React.lazy(() =>
  import('./components/approverVerify/VerificationDone')
);

const ReportContainer = React.lazy(() =>
  import('./components/report/ReportContainer')
);
const CandidatesReportContainer = React.lazy(() =>
  import('./components/report/candidates/CandidatesReportContainer')
);

const DetailReportViewContainer = React.lazy(() =>
  import('./components/report/detailReportView/DetailReportViewContainer')
);
const ReportReviewSessionContainer = React.lazy(() =>
  import('./components/report/reportReviewSession/ReportReviewSessionContainer')
);

const CandidateReportViewList = React.lazy(() =>
  import('./components/report/CandidateReportViewList')
);
const AppRoute = () => {
  const history = useHistory();
  const headerRef = useRef(null);

  const AUTH_LESS_PATHS = ['login', 'forgot-password', 'reset-password', 'third_aeye'];

  const isAuthLessPath = () => {
    return AUTH_LESS_PATHS.some((p) => window.location.pathname.includes(p));
  };

  useEffect(() => {
    // logic for unauthorized tenant
    if (
      localStorageHelper.get('tenant') &&
      !isAuthLessPath() &&
      localStorageHelper.get('tenant') !==
      window.location.pathname.split('/')[1]
    ) {
      alert('Access Denied');
      history.push(ROUTES.DEFAULT);
    } else {
      localStorageHelper.set(
        'tenant',
        window.location.pathname.split('/')[1]?.toLowerCase()
      );
    }
  }, []);

  return (
    <>
      <div className="App-content" ref={headerRef}>
        <ConnectedRouter history={history}>
          <Suspense
            fallback={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                }}
              >
                <CircularProgress />
              </Box>
            }
          >
            <Switch>
              <Route exact path={ROUTES.LOGIN} component={Assess} />
              <Route exact path={ROUTES.TENANT_ROOT} component={Assess} />
              <Route exact path={ROUTES.FORGOT_PASSWORD} component={Assess} />
              <Route exact path={ROUTES.CHANGE_PASSWORD} component={Assess} />
              <Route exact path={ROUTES.THIRD_AEYE} component={Assess} />
              <ProtectedRoute
                exact
                path={ROUTES.TENANT}
                component={TenantContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.ADD_TENANT}
                component={TenantAddEdit}
              />
              <ProtectedRoute
                exact
                path={ROUTES.EDIT_TENANT}
                component={TenantAddEdit}
              />
              <ProtectedRoute
                exact
                path={ROUTES.VIEW_TENANT}
                component={TenantAddEdit}
              />
              <ProtectedRoute
                exact
                path={ROUTES.VIEW_PROCTOR_LIST}
                component={ProctorContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.ADD_PROCTOR}
                component={ProctorAddEdit}
              />
              <ProtectedRoute
                exact
                path={ROUTES.VIEW_PROCTOR}
                component={ProctorView}
              />
              <ProtectedRoute
                exact
                path={ROUTES.EDIT_PROCTOR}
                component={ProctorEdit}
              />
              <ProtectedRoute
                exact
                path={ROUTES.VIEW_TENANT_ADMIN_LIST}
                component={TenantAdminContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.ADD_TENANT_ADMIN}
                component={TenantAdminAdd}
              />
              <ProtectedRoute
                exact
                path={ROUTES.VIEW_TENANT_ADMIN}
                component={TenantAdminView}
              />
              <ProtectedRoute
                exact
                path={ROUTES.EDIT_TENANT_ADMIN}
                component={TenantAdminEdit}
              />
              <ProtectedRoute
                exact
                path={ROUTES.MY_ASSESSMENTS}
                component={AssessmentsViewList}
              />
              <ProtectedRoute
                exact
                path={ROUTES.VIEW_ASSESSMENT}
                component={AssessmentView}
              />

              <ProtectedRoute
                exact
                path={ROUTES.VERIFY_ASSESSMENTS_TEST_TAKER}
                component={ApproverVerifyContainer}
              />
              <ProtectedRoute
                exact
                roles={[Role.superadmin]}
                path={ROUTES.PAGE_NOT_FOUND}
                component={NotFoundPage}
              />
              <ProtectedRoute
                exact
                path={ROUTES.DASHBOARD}
                component={DashboardContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.SUPER_DASHBOARD_VIEW_TEST_TAKERS}
                component={ViewTestTakersDashboardContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.SUPER_DASHBOARD_VIEW_ASSESMENTS}
                component={ViewAssessmentsDashboardContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.PROFILESETTINGS}
                component={ProfileSettingsContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.PROCTORING}
                component={ProctoringContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.SUPERPROCTORING}
                component={SuperProctoringContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.SQUAD}
                component={SquadProctoringContainer}
              />

              <ProtectedRoute
                exact
                path={ROUTES.VERIFICATION_COMPLETE}
                component={VerificationDone}
              />
              <ProtectedRoute
                exact
                path={ROUTES.REPORT}
                component={ReportContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.VIEW_CANDIDATE_DETAIL_REPORT}
                component={DetailReportViewContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.VIEW_CANDIDATES_LIST_REPORT}
                component={CandidatesReportContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.CANDIDATE_REVIEW_SESSION}
                component={ReportReviewSessionContainer}
              />
              <ProtectedRoute
                exact
                path={ROUTES.REPORT_CANDIDATE}
                component={CandidateReportViewList}
              />
              <Redirect from="*" to={ROUTES.DEFAULT} />
            </Switch>
          </Suspense>
        </ConnectedRouter>
      </div>
    </>
  );
};

export default AppRoute;

import axiosInstance from '../../translator';
import { dashboardAPI } from '../../constants/apis/apiEndpoints';

const generateQueryParams = (payload) => {
  const params = {};
  params.limit = payload?.limit || 20;
  params.offset = payload?.offset || 1;
  params.search = payload?.search || '';
  params.ordering = payload?.ordering || '-created_at';
  return params;
};

export const getSuperAdminDashboardApi = async (payload) => {
  return axiosInstance
    .get(dashboardAPI?.superAdmin)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchTenantsList = async (payload) => {
  const params = generateQueryParams(payload);
  return axiosInstance
    .get(dashboardAPI?.tenantList, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchTestTakersList = async (payload) => {
  const params = generateQueryParams(payload.params);

  //Append filters to the query params if exists
  if (payload?.candidate_name) {
    let fullNameArr = payload?.candidate_name?.split(' ');
    if (fullNameArr.length === 2) {
      fullNameArr ? (params.first_name = fullNameArr[0]) : '';
      fullNameArr[1] ? (params.last_name = fullNameArr[1]) : '';
    } else {
      params.candidate_name = payload?.candidate_name;
    }
  }
  payload?.assessment_name
    ? (params.assessment_name = payload?.assessment_name)
    : '';
  payload?.dateBefore ? (params.date_before = payload?.dateBefore) : '';
  payload?.dateAfter ? (params.date_after = payload?.dateAfter) : '';
  if (payload?.isCSV) {
    params.download_csv = true
    params.to_email = payload.to_email;
    params.name = payload.name;
  }

  delete payload?.date;

  return axiosInstance
    .get(`${dashboardAPI?.testTakerList}/${payload?.id}/${payload?.tenantName}`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchAssessmentList = async (payload) => {
  const params = generateQueryParams(payload);
  //Append filters to the query params if exists
  payload?.assessment_name
    ? (params.assessment_name = payload?.assessment_name)
    : '';
  payload?.dateBefore ? (params.date_before = payload?.dateBefore) : '';
  payload?.dateAfter ? (params.date_after = payload?.dateAfter) : '';
  payload?.proctor_name ? (params.proctor_name = payload?.proctor_name) : '';
  if (payload?.isCSV) {
    params.download_csv = true
    params.to_email = payload.to_email;
    params.name = payload.name;
  }

  delete payload?.date;

  return axiosInstance
    .get(`${dashboardAPI?.assessmentList}/${payload?.id}/${payload?.tenantName}`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getTenantAdminDashboardApi = async (payload) => {
  return axiosInstance
    .get(dashboardAPI?.tenantAdmin)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getProctorDashboardApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI?.proctors}/${payload?.user_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUpcomingAssignmentApi = async (payload) => {
  return axiosInstance
    .post(dashboardAPI?.upcomingAssessment, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
